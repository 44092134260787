.modal__main {
    position: fixed;
    width: 100vw;
    height: calc(100vh + 60px);
   top: -30px;
    z-index: 10000;
    background-color: #1f2833;
    text-align: center;
}

.modal__content-bust, .modal__content-bank {
    max-width: 95%;
}

.modal__header {
    padding-top: 70px;
}

.modal__close {
    position: absolute;
    top: 50px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    cursor: pointer;
}

.modal__footer-buttons {
    display: inline-flex;
}

.modal__cancel-button, .modal__accept-button {
    padding: 15px;
    cursor: pointer;
    margin: 20px 10px;
    background-color: #e6e6e6;
    border: 1px solid #09d3ac;
    border-radius: 5px;
    color: #1f2833;
}

.modal__content-bust img, .modal__content-bank img {
    width: 165px;
}

/* Toggle switch */
.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #f90;
    color: #fff;
}
.toggle-switch-disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ccc;
    cursor: not-allowed;
}
.toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #ccc;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}
.toggle-switch.small-switch {
    width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after,
.toggle-switch.small-switch .toggle-switch-inner:before {
    content: "";
    height: 20px;
    line-height: 20px;
}
.toggle-switch.small-switch .toggle-switch-switch {
    width: 16px;
    right: 20px;
    margin: 2px;
}
@media screen and (max-width: 991px) {
    .toggle-switch {
        transform: scale(0.9);
    }
}
@media screen and (max-width: 767px) {
    .toggle-switch {
        transform: scale(0.825);
    }
}
@media screen and (max-width: 575px) {
    .toggle-switch {
        transform: scale(0.75);
    }
}