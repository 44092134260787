@import url('https://fonts.googleapis.com/css?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
	background-color: #1f2833;
	color: #66fcf1;
	padding:0;
	margin:0;
	overflow-x: hidden;
}

h1 {
	font-family: 'Righteous', cursive;
    margin: 30px auto;
    text-align: center;
	opacity: 1;
	position: relative;
	text-transform: uppercase;
}

a {
	text-decoration: none;
}

a, a:visited {
	color: #66fcf1;
}

/* Top action button stylings */
.top__action-buttons {
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: absolute;
	top: 10px;
	right: 20px;
	z-index: 98;
}

.top__action-buttons-active {
	transform: rotate(90deg);
}

.top__action-buttons::before {
	content: '\22EE';
	font-size: 40px;
	color: #66fcf1;
	cursor: pointer;
	z-index: 99;
}

.top__action-buttons-beer {
	text-align: left;
	margin-left: 20px;
	margin-top: -15px;
	font-size: 20px;
}

.top__action-buttons-beer-text {
	font-size: 10px;
	width: 85px;
	margin-left: 15px;
}

/* Top nav buttons whose different locations are
top: 55px
top: 101px
top: 147px
top: 193px
top: 239px */

.top__action-buttons-bank,
.top__action-buttons-bust,
.top__action-buttons-settings,
.top__action-buttons-logout,
.top__action-buttons-about {
	font-family: 'Righteous', cursive;
	text-transform: uppercase;
	text-align: center;
	padding-top: 10px;
	width: 70px;
	height: 30px;
	position: absolute;
	cursor: pointer;
	opacity: 1;
	z-index: 100;
	background-color: #45a29e;
	border: 1px solid #66fcf1;
	border-radius: 3px 0 0 3px;
	right: -100px;
	top: 55px;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}

.top__action-buttons-bust {
	width: 78px;
	top: 55px;
}

.top__action-buttons-settings {
	top: 147px;
	width: 98px;
}

.top__action-buttons-logout {
	cursor: pointer;
	top: 193px;
	width: 86px;
}

.top__action-buttons-about {
	width: 78px;
	top: 101px;
}

.top__action-buttons-bank-active,
.top__action-buttons-bust-active,
.top__action-buttons-settings-active,
.top__action-buttons-logout-active,
.top__action-buttons-about-active {
	right: 0;
}

.top__action-buttons-bank-inactive,
.top__action-buttons-bust-inactive,
.top__action-buttons-settings-inactive,
.top__action-buttons-logout-inactive,
.top__action-buttons-about-inactive {
	opacity: 0;
	visibility: hidden;
}

.spend__main {
	padding-bottom: 150px;
	background-color: #1f2833;
    margin: 0 auto;
    text-align: center;
}

.spend__logged-out {
	margin: 10px auto 10px;
	text-align: center;
	cursor: pointer;
}

.spend__main-signup {
	margin: 0 auto 150px;
}

.spend__main-header {
	position: relative;
	padding-top: 15px;
	font-weight: 600;
}

.spend__date-display {
	display: inline-block;
	float: left;
	width: 50%;
	text-align: center;
}

.spend__added, .spend__spent {
	display: inline-block;
	float: right;
	width: 50%;
	text-align: center;
}

.spend-container {
    margin: 0 auto;
	padding-top: 30px;
    background-color: #1f2833;
	min-height: 100vh;
	overflow-x: hidden;
	position:relative;
}

.spend-container h1 {
	margin: -75px 0 0;
}

.spend__add-section {
    margin: 15px auto 0px;
    text-align: center;
    padding-bottom: 20px;
    display: inline-block;
	max-width: 95%;
}

.spend__add-inputs {
    display: inline-grid;
	position: relative;
}

.spend__add-inputs-input {
	background-color: #ffffff;
	position:relative;
	z-index: 20;
}

.spend__spend-table {
	width: 350px;
	margin: 0 auto;
}

.spend__finalized-section {
    padding: 17px 0 0 26px;
    display: inline-flex;
	position: relative;
}

.spend__daily-section>.spend__finalized-section:first-of-type::before {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.spend__finalized-section:last-of-type::before {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	top: 40px;
}

.spend__finalized-section input {
	background-color: #e6e6e6;
}

.spend__finalized-price {
	border-radius: 0 3px 0 0;
	max-width: 125px !important;
	position: relative;
}

.spend__finalized-price:before {
	content: '$';
	color: #555555;
	position: absolute;
	top: 4px;
	left: 10px;
	font-size: 12px;
}

.spend__finalized-price input {
	padding-left: 20px;
	box-sizing: border-box;
	width: 147px;
	border-radius: 0 3px 0 0;
}

.spend__finalized-name input {
	border-radius: 3px 0 0 0;
	width: 125px;
}

.dollar {
	box-sizing: border-box;
}

.dollar input {
	padding-left: 20px;
	box-sizing: border-box;
}

.dollar:before {
	position: absolute;
	content: "$";
	font-size: 12px;
	left: 10px;
	top: 28px;
	color: black;
}

.spend__finalized-name {
	border-radius: 3px 0 0 0;
	max-width: 125px !important;
}

input {
    padding: 10px;
    border: 1px solid #45a29e;
    border-radius: 3px;
}

input[disabled] {
	color: #000000 !important;
	background: #e6e6e6 !important;
	opacity: 1 !important;
}

.spend__action-buttons {
	width: 85%;
    text-align: center;
    margin: 0 auto;
    display: block;
    border-bottom: 1px solid #45a29e;
}

button {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 3px;
    margin: 0 15px 20px;
	cursor: pointer;
}

.item-tabled {
	background-color: #45a29e;
	border-radius: 0 0 3px 3px;
	margin-top: -2px;
	padding: 2px;
	box-sizing: border-box;
	width: 294px;
	position: absolute;
}

.spend-image {
	height: 188px;
	text-align: center;
	margin: 0 auto 15px;
}

.spend-image>img {
	height: 300px;
	opacity: .4;
	z-index: 25;
}

.spend__update-remove {
	display: flex;
}

.spend__update-remove>button {
	margin: 0;
	padding: 18px 0px 10px 11px;
}

.spend__update-remove-collapsed {
	content: '';
	width: 24px;
}

.spend__remove {
	background-color: #1f2833;
	border: 0;
}

.spend__remove::before {
	font-family: 'Righteous', cursive;
	text-transform: uppercase;
	content: 'X';
	font-size: 20px;
	height: 57px;
	width: 57px;
	margin: 0;
	color: #66fcf1;
	cursor: pointer;
}

.spend__update {
	background-color: #1f2833;
	border: 0;
	font-size: 15px;
	height: 57px;
	width: 57px;
	margin: 0;
	color: #66fcf1;
	cursor: pointer;
}

.remove-image {
	height:25px;
}

/*Bottom action button stylings*/
.bottom__action-buttons {
	position: fixed;
	width: 100%;
	bottom: 0;
	/*display: flex;*/
	display:none;
	background-color: #45a29e;
	font-family: 'Righteous', cursive;
	text-transform: uppercase;
	font-size: 20px;
	vertical-align: middle;
	border-top: 2px solid #66fcf1;
}

.bottom__action-buttons-bank, .bottom__action-buttons-bust {
	width: 50%;
	height: 70px;
	padding-top: 20px;
	box-sizing: border-box;
	cursor: pointer;
}

.bottom__action-buttons-bank {
	border-right: #66fcf1 1px solid;
}

.bottom__action-buttons-bust {
	border-left: #66fcf1 1px solid;
}

/* Different time frames, classes for them, and the sidebar for them */

.spend__daily-section-active, .spend__weekly-section-active, .spend__beyond-section-active {
	height: fit-content;
}

.spend__today-collapsed {
	margin-bottom: 13px;
}

.spend__today:before,
.spend__today-collapsed:before,
.spend__this-week:before,
.spend__this-week-collapsed:before,
.spend__before-week:before,
.spend__before-week-collapsed:before {
	display: inline-block;
	position: absolute;
	left: -35px;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	box-sizing: border-box;
	cursor: pointer;
	font-size: 14px;
	text-align: right;
}

.spend__today:before,
.spend__today-collapsed:before {
	content:'hold';
	color: #993333;
	background-color: #993333;
    top: 40px;
	width: 73.5px;
	height: 21px;
}

.spend__today:first-of-type:before,
.spend__today-collapsed:first-of-type:before {
	content: 'Day \22EE';
	background-color: #993333;
	color: #e6e6e6;
	padding-right: 10px;
	box-sizing: border-box;
}

.spend__today-alone {
	border: 3px;
}

.spend__this-week:first-of-type:before,
.spend__this-week-collapsed:first-of-type:before {
	content: 'Week \22EE \00a0';
	color: #ffffff;
}

.spend__this-week:before,
.spend__this-week-collapsed:before {
	content: 'hold';
	color: darkorange;
	background-color: darkorange;
    top: 40px;
	width: 74px;
	height: 21px;
	padding-right: 3px;
	padding-top: 1px;
}

.spend__before-week:first-of-type:before,
.spend__before-week-collapsed:first-of-type:before {
	content:'Beyond \22EE ';
	color: #ffffff;
}

.spend__before-week:before,
.spend__before-week-collapsed:before {
	content: 'hold';
	color: #9ea9ed;
	background-color: #9ea9ed;
    top: 38px;
	width: 74px;
	height: 21px;
	padding-right: 5px;
	padding-top: 1px;
}

.spend__daily-section-inactive {
	padding-bottom: 2px;
}

.spend__weekly-section-inactive {
	padding-bottom: 17px;
}

.none {
	display: none;
}

/* Media Queries */

@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px) {
	.dollar:before {
		top: 33px !important;
	}
}

@media screen and (max-width: 400px) {
	.spend__spend-table {
		width: 270px;
	}
	.item-tabled {
		width: 212px;
	}
	.spend__finalized-name input {
		width: 118px;
	}
	.spend__finalized-price input {
		padding-left: 20px;
		box-sizing: border-box;
		width: 72px;
		text-align: right;
		padding-right: 10px;
	}
}

/* Loading spinner */
#loading {
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 3px solid rgba(255,255,255,.3);
	border-radius: 50%;
	border-top-color: #fff;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	margin-top: 50px;
}

@keyframes spin {
	to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	to { -webkit-transform: rotate(360deg); }
}