.intro__section {
    background-color: #1f2833;
    border-top: 7px solid #933;
    border-bottom: 7px solid #933;
    height: 300px;
    width: 100%;
    padding-bottom: 30px;
    padding-right: 30px;
    position: relative;
}

.intro__section h1 {
    margin: -75px 0 0 0;
}

.about-image {
    height: 100px;
    text-align: right;
    margin: 0 auto 15px;
}

.about-image > img {
    height: 300px;
    opacity: .4;
    z-index: 25;
}

.intro__section h1, .intro__section h2 {
    margin-right: 50px;
    text-align: right;
    color: #ffffff;
    z-index: 50 !important;
    opacity: 1;
    position: relative;
}

.wrapper {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 4px;
    background-color: #293443;
}

.section, .section2 {
    position: relative;
    height: 98vh;
    display: flex;
    color: white;
    text-shadow: 0 0 5px #000;
}

.section2 {
    height: 0;
    margin-top: -350px;
}

.parallax::after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(1.5);
    background-size: 100%;
    z-index: -1;
}

/* The styling for the static div. */
.static, .static2 {
    background-repeat: no-repeat;
    z-index: -3;
    margin-top: -500px;
}

.static {
    background-image: url('images/iconfinder_starship_alone.png');
}

.bg1::after {
    background-image: url('images/first-panels.png');
    opacity: 1;
    background-size: cover;
}

.bg2::after {
    background-image: url('images/first-panels.png');
    opacity: 1;
    background-size: cover;
}

.star1,
.star2,
.star3,
.star4 {
    position: absolute;
}

.star1 {
    top: -30px;
    left: 40px;
}

.star2 {
    top: 20px;
    left: 137px;
}

.star4 {
    top: -250px;
    right: 30px;
}

.about {
    font-size: 24px;
}

.about__existing {
    float: right;
    position: relative;
    font-size: 16px;
    width: 205px;
}

.about__existing-crushin, .about__existing-sign-in {
    position: absolute;
    right: 55px;
}

.about__existing-sign-in {
    top: 20px;
    cursor: pointer;
}

.about__1 {
    color: #ffffff;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

@media screen and (max-width: 404px) {
    .about__1 {
        max-width: 350px;
    }
}

.about__2 {
    position: absolute;
    top: -170px;
    left: 100px;
}

.about__3, .about__4 {
    background-color: #1f2833;
    border-top: 7px solid #933;
    border-bottom: 7px solid #933;
    position: absolute;
    top: -175px;
    width: 100%;
    text-align: right;
    height: 200px;
    padding-top: 35px;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
}

.about__4 {
    height: 375px;
}

.signup__section {
    position: relative;
    padding-right: 40px;
}

.section2 h1 {
    font-size: 30px;
    margin:0;
}

.section2 h2 {
    z-index: 25;
    position: relative;
    text-align: center;
    margin:0;
}

.signup-image {
    z-index: 25;
}

.signup-image > img {
    height: 300px;
    margin-top: -40px;
}

.about-footer {
    margin: 275px 20px 30px;
    text-align: right;
    font-size: 14px;
}

hr {
    width: 50px;
    height: 0px;
    text-align: right;
    margin: 15px 0 15px auto;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .about__2 {
        top: -82px;
    }
}

@media screen and (min-width: 715px) {
    .main {
        background-color: #1f2833;
        max-width: 90%;
        margin: 50px auto;
    }
}
@media screen and (min-width: 600px) {
    body {
        background-color: #dde3ea;
    }

    .main {
        border: 7px solid #993333;
        border-radius: 5px;
    }

    .intro__section {
        border-top: 0;
    }

    .signup__section {
        padding-left: 40px;
    }
}

@media screen and (max-width: 540px) {
    .about__4 {
        height: 575px;
    }

    .signup__section {
        position: absolute;
        left: calc(50% - 20vw);
        padding-top: 50px;
    }

    .signup-image > img {
        margin-top: 170px;
    }

    .about-footer {
        margin-top: 485px;
    }
}

@media screen and (max-width: 445px) {
    .about__4 {
        text-align: center;
    }

    .signup__section {
        left: calc(50% - 29vw);
        padding-top: 72px;
    }
}
