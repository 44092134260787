@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
    max-width: 100% !important;
}

.column-header-1, .column-header-2 {
    width: 33%;
    display: inline-block;
}

.column-content-1, .column-content-2, .column-content-3 {
    width: 32%;
    display: inline-block;
}
