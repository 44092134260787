@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
body {
    font-family: 'Montserrat', sans-serif;
    max-width: 100% !important;
}

.column-header-1, .column-header-2 {
    width: 33%;
    display: inline-block;
}

.column-content-1, .column-content-2, .column-content-3 {
    width: 32%;
    display: inline-block;
}

.modal__main {
    position: fixed;
    width: 100vw;
    height: calc(100vh + 60px);
   top: -30px;
    z-index: 10000;
    background-color: #1f2833;
    text-align: center;
}

.modal__content-bust, .modal__content-bank {
    max-width: 95%;
}

.modal__header {
    padding-top: 70px;
}

.modal__close {
    position: absolute;
    top: 50px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    cursor: pointer;
}

.modal__footer-buttons {
    display: -webkit-inline-flex;
    display: inline-flex;
}

.modal__cancel-button, .modal__accept-button {
    padding: 15px;
    cursor: pointer;
    margin: 20px 10px;
    background-color: #e6e6e6;
    border: 1px solid #09d3ac;
    border-radius: 5px;
    color: #1f2833;
}

.modal__content-bust img, .modal__content-bank img {
    width: 165px;
}

/* Toggle switch */
.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #f90;
    color: #fff;
}
.toggle-switch-disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ccc;
    cursor: not-allowed;
}
.toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #ccc;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}
.toggle-switch.small-switch {
    width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after,
.toggle-switch.small-switch .toggle-switch-inner:before {
    content: "";
    height: 20px;
    line-height: 20px;
}
.toggle-switch.small-switch .toggle-switch-switch {
    width: 16px;
    right: 20px;
    margin: 2px;
}
@media screen and (max-width: 991px) {
    .toggle-switch {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
}
@media screen and (max-width: 767px) {
    .toggle-switch {
        -webkit-transform: scale(0.825);
                transform: scale(0.825);
    }
}
@media screen and (max-width: 575px) {
    .toggle-switch {
        -webkit-transform: scale(0.75);
                transform: scale(0.75);
    }
}
body {
	background-color: #1f2833;
	color: #66fcf1;
	padding:0;
	margin:0;
	overflow-x: hidden;
}

h1 {
	font-family: 'Righteous', cursive;
    margin: 30px auto;
    text-align: center;
	opacity: 1;
	position: relative;
	text-transform: uppercase;
}

a {
	text-decoration: none;
}

a, a:visited {
	color: #66fcf1;
}

/* Top action button stylings */
.top__action-buttons {
	transition: 0.5s;
	position: absolute;
	top: 10px;
	right: 20px;
	z-index: 98;
}

.top__action-buttons-active {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
}

.top__action-buttons::before {
	content: '\22EE';
	font-size: 40px;
	color: #66fcf1;
	cursor: pointer;
	z-index: 99;
}

.top__action-buttons-beer {
	text-align: left;
	margin-left: 20px;
	margin-top: -15px;
	font-size: 20px;
}

.top__action-buttons-beer-text {
	font-size: 10px;
	width: 85px;
	margin-left: 15px;
}

/* Top nav buttons whose different locations are
top: 55px
top: 101px
top: 147px
top: 193px
top: 239px */

.top__action-buttons-bank,
.top__action-buttons-bust,
.top__action-buttons-settings,
.top__action-buttons-logout,
.top__action-buttons-about {
	font-family: 'Righteous', cursive;
	text-transform: uppercase;
	text-align: center;
	padding-top: 10px;
	width: 70px;
	height: 30px;
	position: absolute;
	cursor: pointer;
	opacity: 1;
	z-index: 100;
	background-color: #45a29e;
	border: 1px solid #66fcf1;
	border-radius: 3px 0 0 3px;
	right: -100px;
	top: 55px;
	transition: 0.5s;
}

.top__action-buttons-bust {
	width: 78px;
	top: 55px;
}

.top__action-buttons-settings {
	top: 147px;
	width: 98px;
}

.top__action-buttons-logout {
	cursor: pointer;
	top: 193px;
	width: 86px;
}

.top__action-buttons-about {
	width: 78px;
	top: 101px;
}

.top__action-buttons-bank-active,
.top__action-buttons-bust-active,
.top__action-buttons-settings-active,
.top__action-buttons-logout-active,
.top__action-buttons-about-active {
	right: 0;
}

.top__action-buttons-bank-inactive,
.top__action-buttons-bust-inactive,
.top__action-buttons-settings-inactive,
.top__action-buttons-logout-inactive,
.top__action-buttons-about-inactive {
	opacity: 0;
	visibility: hidden;
}

.spend__main {
	padding-bottom: 150px;
	background-color: #1f2833;
    margin: 0 auto;
    text-align: center;
}

.spend__logged-out {
	margin: 10px auto 10px;
	text-align: center;
	cursor: pointer;
}

.spend__main-signup {
	margin: 0 auto 150px;
}

.spend__main-header {
	position: relative;
	padding-top: 15px;
	font-weight: 600;
}

.spend__date-display {
	display: inline-block;
	float: left;
	width: 50%;
	text-align: center;
}

.spend__added, .spend__spent {
	display: inline-block;
	float: right;
	width: 50%;
	text-align: center;
}

.spend-container {
    margin: 0 auto;
	padding-top: 30px;
    background-color: #1f2833;
	min-height: 100vh;
	overflow-x: hidden;
	position:relative;
}

.spend-container h1 {
	margin: -75px 0 0;
}

.spend__add-section {
    margin: 15px auto 0px;
    text-align: center;
    padding-bottom: 20px;
    display: inline-block;
	max-width: 95%;
}

.spend__add-inputs {
    display: inline-grid;
	position: relative;
}

.spend__add-inputs-input {
	background-color: #ffffff;
	position:relative;
	z-index: 20;
}

.spend__spend-table {
	width: 350px;
	margin: 0 auto;
}

.spend__finalized-section {
    padding: 17px 0 0 26px;
    display: -webkit-inline-flex;
    display: inline-flex;
	position: relative;
}

.spend__daily-section>.spend__finalized-section:first-of-type::before {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.spend__finalized-section:last-of-type::before {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	top: 40px;
}

.spend__finalized-section input {
	background-color: #e6e6e6;
}

.spend__finalized-price {
	border-radius: 0 3px 0 0;
	max-width: 125px !important;
	position: relative;
}

.spend__finalized-price:before {
	content: '$';
	color: #555555;
	position: absolute;
	top: 4px;
	left: 10px;
	font-size: 12px;
}

.spend__finalized-price input {
	padding-left: 20px;
	box-sizing: border-box;
	width: 147px;
	border-radius: 0 3px 0 0;
}

.spend__finalized-name input {
	border-radius: 3px 0 0 0;
	width: 125px;
}

.dollar {
	box-sizing: border-box;
}

.dollar input {
	padding-left: 20px;
	box-sizing: border-box;
}

.dollar:before {
	position: absolute;
	content: "$";
	font-size: 12px;
	left: 10px;
	top: 28px;
	color: black;
}

.spend__finalized-name {
	border-radius: 3px 0 0 0;
	max-width: 125px !important;
}

input {
    padding: 10px;
    border: 1px solid #45a29e;
    border-radius: 3px;
}

input[disabled] {
	color: #000000 !important;
	background: #e6e6e6 !important;
	opacity: 1 !important;
}

.spend__action-buttons {
	width: 85%;
    text-align: center;
    margin: 0 auto;
    display: block;
    border-bottom: 1px solid #45a29e;
}

button {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 3px;
    margin: 0 15px 20px;
	cursor: pointer;
}

.item-tabled {
	background-color: #45a29e;
	border-radius: 0 0 3px 3px;
	margin-top: -2px;
	padding: 2px;
	box-sizing: border-box;
	width: 294px;
	position: absolute;
}

.spend-image {
	height: 188px;
	text-align: center;
	margin: 0 auto 15px;
}

.spend-image>img {
	height: 300px;
	opacity: .4;
	z-index: 25;
}

.spend__update-remove {
	display: -webkit-flex;
	display: flex;
}

.spend__update-remove>button {
	margin: 0;
	padding: 18px 0px 10px 11px;
}

.spend__update-remove-collapsed {
	content: '';
	width: 24px;
}

.spend__remove {
	background-color: #1f2833;
	border: 0;
}

.spend__remove::before {
	font-family: 'Righteous', cursive;
	text-transform: uppercase;
	content: 'X';
	font-size: 20px;
	height: 57px;
	width: 57px;
	margin: 0;
	color: #66fcf1;
	cursor: pointer;
}

.spend__update {
	background-color: #1f2833;
	border: 0;
	font-size: 15px;
	height: 57px;
	width: 57px;
	margin: 0;
	color: #66fcf1;
	cursor: pointer;
}

.remove-image {
	height:25px;
}

/*Bottom action button stylings*/
.bottom__action-buttons {
	position: fixed;
	width: 100%;
	bottom: 0;
	/*display: flex;*/
	display:none;
	background-color: #45a29e;
	font-family: 'Righteous', cursive;
	text-transform: uppercase;
	font-size: 20px;
	vertical-align: middle;
	border-top: 2px solid #66fcf1;
}

.bottom__action-buttons-bank, .bottom__action-buttons-bust {
	width: 50%;
	height: 70px;
	padding-top: 20px;
	box-sizing: border-box;
	cursor: pointer;
}

.bottom__action-buttons-bank {
	border-right: #66fcf1 1px solid;
}

.bottom__action-buttons-bust {
	border-left: #66fcf1 1px solid;
}

/* Different time frames, classes for them, and the sidebar for them */

.spend__daily-section-active, .spend__weekly-section-active, .spend__beyond-section-active {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.spend__today-collapsed {
	margin-bottom: 13px;
}

.spend__today:before,
.spend__today-collapsed:before,
.spend__this-week:before,
.spend__this-week-collapsed:before,
.spend__before-week:before,
.spend__before-week-collapsed:before {
	display: inline-block;
	position: absolute;
	left: -35px;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	box-sizing: border-box;
	cursor: pointer;
	font-size: 14px;
	text-align: right;
}

.spend__today:before,
.spend__today-collapsed:before {
	content:'hold';
	color: #993333;
	background-color: #993333;
    top: 40px;
	width: 73.5px;
	height: 21px;
}

.spend__today:first-of-type:before,
.spend__today-collapsed:first-of-type:before {
	content: 'Day \22EE';
	background-color: #993333;
	color: #e6e6e6;
	padding-right: 10px;
	box-sizing: border-box;
}

.spend__today-alone {
	border: 3px;
}

.spend__this-week:first-of-type:before,
.spend__this-week-collapsed:first-of-type:before {
	content: 'Week \22EE \00a0';
	color: #ffffff;
}

.spend__this-week:before,
.spend__this-week-collapsed:before {
	content: 'hold';
	color: darkorange;
	background-color: darkorange;
    top: 40px;
	width: 74px;
	height: 21px;
	padding-right: 3px;
	padding-top: 1px;
}

.spend__before-week:first-of-type:before,
.spend__before-week-collapsed:first-of-type:before {
	content:'Beyond \22EE ';
	color: #ffffff;
}

.spend__before-week:before,
.spend__before-week-collapsed:before {
	content: 'hold';
	color: #9ea9ed;
	background-color: #9ea9ed;
    top: 38px;
	width: 74px;
	height: 21px;
	padding-right: 5px;
	padding-top: 1px;
}

.spend__daily-section-inactive {
	padding-bottom: 2px;
}

.spend__weekly-section-inactive {
	padding-bottom: 17px;
}

.none {
	display: none;
}

/* Media Queries */

@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px) {
	.dollar:before {
		top: 33px !important;
	}
}

@media screen and (max-width: 400px) {
	.spend__spend-table {
		width: 270px;
	}
	.item-tabled {
		width: 212px;
	}
	.spend__finalized-name input {
		width: 118px;
	}
	.spend__finalized-price input {
		padding-left: 20px;
		box-sizing: border-box;
		width: 72px;
		text-align: right;
		padding-right: 10px;
	}
}

/* Loading spinner */
#loading {
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 3px solid rgba(255,255,255,.3);
	border-radius: 50%;
	border-top-color: #fff;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	margin-top: 50px;
}

@keyframes spin {
	to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	to { -webkit-transform: rotate(360deg); }
}
.intro__section {
    background-color: #1f2833;
    border-top: 7px solid #933;
    border-bottom: 7px solid #933;
    height: 300px;
    width: 100%;
    padding-bottom: 30px;
    padding-right: 30px;
    position: relative;
}

.intro__section h1 {
    margin: -75px 0 0 0;
}

.about-image {
    height: 100px;
    text-align: right;
    margin: 0 auto 15px;
}

.about-image > img {
    height: 300px;
    opacity: .4;
    z-index: 25;
}

.intro__section h1, .intro__section h2 {
    margin-right: 50px;
    text-align: right;
    color: #ffffff;
    z-index: 50 !important;
    opacity: 1;
    position: relative;
}

.wrapper {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-perspective: 4px;
            perspective: 4px;
    background-color: #293443;
}

.section, .section2 {
    position: relative;
    height: 98vh;
    display: -webkit-flex;
    display: flex;
    color: white;
    text-shadow: 0 0 5px #000;
}

.section2 {
    height: 0;
    margin-top: -350px;
}

.parallax::after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateZ(-1px) scale(1.5);
            transform: translateZ(-1px) scale(1.5);
    background-size: 100%;
    z-index: -1;
}

/* The styling for the static div. */
.static, .static2 {
    background-repeat: no-repeat;
    z-index: -3;
    margin-top: -500px;
}

.static {
    background-image: url(/static/media/iconfinder_starship_alone.b87b743b.png);
}

.bg1::after {
    background-image: url(/static/media/first-panels.d51f3a00.png);
    opacity: 1;
    background-size: cover;
}

.bg2::after {
    background-image: url(/static/media/first-panels.d51f3a00.png);
    opacity: 1;
    background-size: cover;
}

.star1,
.star2,
.star3,
.star4 {
    position: absolute;
}

.star1 {
    top: -30px;
    left: 40px;
}

.star2 {
    top: 20px;
    left: 137px;
}

.star4 {
    top: -250px;
    right: 30px;
}

.about {
    font-size: 24px;
}

.about__existing {
    float: right;
    position: relative;
    font-size: 16px;
    width: 205px;
}

.about__existing-crushin, .about__existing-sign-in {
    position: absolute;
    right: 55px;
}

.about__existing-sign-in {
    top: 20px;
    cursor: pointer;
}

.about__1 {
    color: #ffffff;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

@media screen and (max-width: 404px) {
    .about__1 {
        max-width: 350px;
    }
}

.about__2 {
    position: absolute;
    top: -170px;
    left: 100px;
}

.about__3, .about__4 {
    background-color: #1f2833;
    border-top: 7px solid #933;
    border-bottom: 7px solid #933;
    position: absolute;
    top: -175px;
    width: 100%;
    text-align: right;
    height: 200px;
    padding-top: 35px;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
}

.about__4 {
    height: 375px;
}

.signup__section {
    position: relative;
    padding-right: 40px;
}

.section2 h1 {
    font-size: 30px;
    margin:0;
}

.section2 h2 {
    z-index: 25;
    position: relative;
    text-align: center;
    margin:0;
}

.signup-image {
    z-index: 25;
}

.signup-image > img {
    height: 300px;
    margin-top: -40px;
}

.about-footer {
    margin: 275px 20px 30px;
    text-align: right;
    font-size: 14px;
}

hr {
    width: 50px;
    height: 0px;
    text-align: right;
    margin: 15px 0 15px auto;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .about__2 {
        top: -82px;
    }
}

@media screen and (min-width: 715px) {
    .main {
        background-color: #1f2833;
        max-width: 90%;
        margin: 50px auto;
    }
}
@media screen and (min-width: 600px) {
    body {
        background-color: #dde3ea;
    }

    .main {
        border: 7px solid #993333;
        border-radius: 5px;
    }

    .intro__section {
        border-top: 0;
    }

    .signup__section {
        padding-left: 40px;
    }
}

@media screen and (max-width: 540px) {
    .about__4 {
        height: 575px;
    }

    .signup__section {
        position: absolute;
        left: calc(50% - 20vw);
        padding-top: 50px;
    }

    .signup-image > img {
        margin-top: 170px;
    }

    .about-footer {
        margin-top: 485px;
    }
}

@media screen and (max-width: 445px) {
    .about__4 {
        text-align: center;
    }

    .signup__section {
        left: calc(50% - 29vw);
        padding-top: 72px;
    }
}

